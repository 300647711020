<template>
    <nav class="top-nav" id="idTopNav">
        <a href="/" title="Калькулятор" class="active">kalkulyator.com.ua</a>

        <div class="dropdown">
            <button class="dropbtn" v-on:click="menuClick('idFinance')">Фінанси
                <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-content" id="idFinance">
                <a href="/znyzhka" title="калькулятор знижок">Знижка</a>
                <a href="/iban" title="валідатор IBAN">IBAN</a>
            </div>
        </div>

        <div class="dropdown">
            <button class="dropbtn" v-on:click="menuClick('idMathematics')">Математика
                <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-content" id="idMathematics">
                <a href="/vidsotok" title="калькулятор відсотків">Відсоток</a>
                <a href="/dyspersiya" title="калькулятор дисперсії">Дисперсія</a>
                <a href="/dotychna" title="калькулятор відсотків">Дотична</a>
                <a href="/kvadratnyy-korin" title="калькулятор квадратного кореня">Квадратний корінь</a>
                <a href="/kub" title="куб калькулятор">Куб</a>
                <a href="/loharyfm" title="калькулятор логарифмів">Логарифм</a>
            </div>
        </div>

        <div class="dropdown">
            <button class="dropbtn" v-on:click="menuClick('idPhysics')">Фізика
            <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-content" id="idPhysics">
                <a href="/hustyna" title="калькулятор щільності">Густина</a>
                <a href="/om" title="Калькулятор закону Ома">Ом</a>
                <a href="/temperatura" title="перетворювач температури">Температура</a>
            </div>
        </div>

        <div class="dropdown">
            <button class="dropbtn" v-on:click="menuClick('idTime')">Час
                <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-content" id="idTime">
                <a href="/hodyny" title="калькулятор годин">Години</a>
            </div>
        </div>

        <a href="javascript:void(0);" style="font-size:15px;" class="icon" v-on:click="hamburgerMenuClick()">&#9776;</a>
    </nav>
</template>

<script>
export default {
    name: "TopNav",
    methods: {
        hamburgerMenuClick: function () {
            var x = document.getElementById("idTopNav");
            if (x.className === "top-nav") {
                x.className += " responsive";
            } else {
                x.className = "top-nav";

                var p = document.getElementById("idPhysics");
                p.className = "dropdown-content";
            }
        },
        /**
        *
        * @param {string} selectedId
        */
        menuClick: function (selectedId) {
            var x = document.getElementById(selectedId);
            if (x.className === "dropdown-content") {
                x.className += " aaaa";
            } else {
                x.className = "dropdown-content";
            }
        }
    }
}
</script>

<style scoped>
.top-nav {
    overflow: hidden;
    background-color: #333;
    font-family: Arial !important;
    font: 1em/1em Arial;
}

.top-nav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.active {
    background-color: #0063dc;
    color: white;
}

.top-nav .icon {
    display: none;
}

.dropdown {
    float: left;
    overflow: hidden;
}

.dropdown .dropbtn {
    font-size: 17px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.top-nav a:hover, .dropdown:hover .dropbtn {
    background-color: #555;
    color: white;
}

.dropdown-content a:hover {
    background-color: #ddd;
    color: black;
}

@media only screen and (min-width: 650px) {
        .dropdown:hover .dropdown-content {
        display: block;
    }
}

.aaaa {
    display: block;
}

@media screen and (max-width: 740px) {
    .top-nav {
        margin-left: -10px;
        margin-right: -10px;
    }

    .top-nav a:not(:first-child), .dropdown .dropbtn {
            display: none;
    }

    .top-nav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 740px) {
    .top-nav.responsive {
        position: relative;
    }

    .top-nav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    .top-nav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }

    .top-nav.responsive .dropdown {
        float: none;
    }

    .top-nav.responsive .dropdown-content {
        position: relative;
    }

    .top-nav.responsive .dropdown .dropbtn {
        display: block;
        width: 100%;
        text-align: left;
    }
}
</style>